<template>
    <div class="con-wrap">
        <CarrotTitle title="교육 점수 등록하기"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <table class="table-row w-700px">
                    <colgroup>
                        <col style="width:160px">
                        <col style="width:540px">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>*교육명</th>
                            <td>
                                <input type="text" class="w-100per" v-model="mod.title">
                            </td>
                        </tr>
                        <tr>
                            <th>*교육기간</th>
                            <td>
                                <carrot-date-picker v-model="mod.sdate" class="w-120px dp-inblock"></carrot-date-picker>
                                ~
                                <carrot-date-picker v-model="mod.edate" class="w-120px dp-inblock"></carrot-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th>*교육이수 시간</th>
                            <td>
                                <input type="text" class="w-100px" v-model="mod.required_time"> * 숫자 및 소수점 1자리까지 입력 가능합니다. (0.5 = 30분)

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="clear"></div>
            </div>

            <div class="mt-40 mb-20">
                <div class="mt-10">
                    <span class="block-title float-left mt-10">이수자 등록</span>
                    <div class="clear"></div>
                </div>
                <div class="mt-20">
                    <carrot-position v-model="mod.position" type="A" @change="mod.doSearch" class="w-200px"></carrot-position>
                    <carrot-dept v-model="mod.office" @change="mod.doSearch" class="w-200px ml-5"></carrot-dept>
                    <carrot-team v-model="mod.team" :idx_office="mod.office" @change="mod.doSearch" class="w-200px ml-5"></carrot-team>
                </div>

                <div class="mt-40">
                    <table class="table-col w-800px float-left">
                        <colgroup>
                            <col width="40">
                            <col width="60">
                            <col width="160">
                            <col width="160">
                            <col width="160">
                            <col width="*">
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>No</th>
                                <th>직급</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>이름</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in mod.list" :key="i">
                                <td><input type="checkbox" v-model="mod.selected" :value="irow.idx" @change="mod.reloadAppl(irow)"></td>
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.position_name }}</td>
                                <td>{{ irow.office_name }}</td>
                                <td class="txt-ellipsis">{{ irow.team_name }}</td>
                                <td>{{ irow.ename + '(' + irow.kname + ')' }}</td>
                            </tr>
                            <tr v-if="mod.total==0">
                                <td colspan="6">본부/팀을 선택하세요.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="appl-box">
                        <div class="appl-title ">
                            <span class="block-title mt-10">이수자 대상자</span>
                            <div class="clear"></div>
                        </div>
                        <div class="appl-list">
                            <div v-for="(irow, i) in mod.applicant" :key="i" class="item">{{ irow.kname + ' (' + irow.ename + ')' }} <span class="btn-view" @click="mod.doDelete(irow.idx)"><img src="@/assets/img/close-877.svg" alt="close"></span></div>
                            <div v-if="mod.applicant.length==0" class="item txt-center">대상자를 선택하세요.</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="mt-20">
                    <button class="btn-default float-left mt-30" @click="mod.goList">목록</button>
                    <button class="btn-default float-right mt-30" @click="mod.doSubmit">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotPosition from '@/components/common/CarrotPosition.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'


export default {
    layout:"myPIMS",
    components: {
        CarrotDatePicker,
        CarrotPosition,
        CarrotDept,
        CarrotTeam
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const mod = reactive({
            idx : 0,

            title : "",
            sdate : "",
            edate : "",
            required_time : "",
            selected : [],
            applicant : [],

            position : 0,
            office   : 0,
            team     : 0,

            list : [], total : 0,

            reloadAppl : (obj) => {
                let isAdd = true;
                let sel = obj.idx;
                for(let j=0; j<mod.applicant.length; j++){
                    let jrow = mod.applicant[j];

                    if( sel == jrow.idx ){
                        mod.applicant.splice(j, 1);
                        isAdd = false;
                        break;
                    }
                }

                if( isAdd == true ){
                    mod.applicant.splice(mod.applicant, 0, {
                        idx : obj.idx,
                        kname : obj.kname,
                        ename : obj.ename
                    });
                }
            },

            doDelete : (idx) => {
                for(let i=0; i<mod.selected.length; i++){
                    let sel = mod.selected[i];
                    if( sel == idx ){
                        mod.selected.splice(i, 1);
                        break;
                    }
                }
                for(let i=0; i<mod.applicant.length; i++){
                    let irow = mod.applicant[i];
                    if( irow.idx == idx ){
                        mod.applicant.splice(i, 1);
                        break;
                    }
                }
            },

            doSubmit : () => {
                let params = {
                    idx : mod.idx,
                    title : mod.title,
                    sdate : mod.sdate,
                    edate : mod.edate,
                    required_time : mod.required_time,
                    selected : mod.selected
                };

                if( !params.title ){
                    alert("교육 명을 입력해 주세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    alert("교육기간을 입력해 주세요.");
                    return;
                }
                if( !params.required_time ){
                    alert("교육 이수 시간을 입력해 주세요.");
                    return;
                }
                if( !params.selected.length ){
                    alert("이수자를 등록해 주세요.");
                    return;
                }
                axios.post("/rest/mypims/modEducation", params).then((res) => {
                    if( res.data.err == 0 ){
                        mod.doSearchInfo();
                        toast.success("등록하였습니다.");
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearchInfo : () => {
                let params = {
                    idx : mod.idx
                };
                axios.get("/rest/mypims/getEducationInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.title = res.data.title;
                        mod.sdate = res.data.sdate;
                        mod.edate = res.data.edate;
                        mod.required_time = res.data.required_time;

                        mod.applicant  = res.data.list;

                        mod.selected = [];
                        for(let i=0; i<mod.applicant.length; i++){
                            mod.selected.push(mod.applicant[i].idx);
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    page : 1,
                    rows : 1000,
                    position : mod.position,
                    office   : mod.office,
                    team     : mod.team,

                };
                if( !params.office ) return;

                axios.get('/rest/mypims/getEduHqApplicant', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.list  = res.data.list;
                        mod.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            goList : () => {
                router.push({
                    name : "myPIMS-EducationList"
                });
            },
        });

        
        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;
            if( !mod.idx ){
                router.back(-1);
            }

            mod.doSearchInfo();
            mod.doSearch();
        });

        return {mod};
    }
}
</script>

<style lang="scss" scoped>
.appl-box {
    width:300px; float:left; margin-left:10px; border-top:2px solid #222;
    .appl-title {
        line-height:48px; background-color:#f9f9f9; text-align:center; border-bottom:1px solid #eee;
    }
    .appl-list {
        min-height:300px; overflow:hidden; overflow-y:auto;
        .item {
            line-height:42px; padding:1px 0 1px 10px; border-bottom:1px solid #ddd;
            .btn-view {
                display:inline-block; width:20px; height:20px; text-align:center;
            }
            &:hover {
                background-color:rgba(0,0,0,0.05);
            }
        }
    }
}
</style>